.powered-by-element
	background-color: $background-color
	display: flex
	align-items: center
	justify-content: center
	padding: 10px

	p
		margin: 0
		font-size: 12px
		color: black
		display: flex
		align-items: center
		justify-content: center
		padding: 10px
		border-radius: 15px
		background-color: #fff
		width: fit-content

		a
			text-decoration: none
			font-size: 16px
			padding: 0 5px
			display: flex
			align-items: center
			justify-content: center
			gap: 10px
			color: #0057b8

			&:hover
				text-decoration: underline

			img
				border-radius: 5px
				width: 30px
				height: 30px
