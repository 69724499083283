.hero-section
	background: $base-color
	color: $light-text-color
	padding: 20px
	text-align: center

	.headline
		margin: auto
		max-width: 600px

		h1
			position: relative
			margin-bottom: 60px

			&::after
				position: absolute
				bottom: -35px
				left: calc(50% - 50px)
				content: ''
				background: $light-text-color
				height: 10px
				width: 100px

				@include rounded-corners

@media (min-width: $screen-md-min) // Tablet and up
	.hero-section
		padding: 50px
