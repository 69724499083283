@mixin basic-transition
	transition: all 0.25s

@mixin rounded-corners
	border-radius: 5px

@mixin first-last-child-zero
	&:first-child
		margin-top: 0

	&:last-child
		margin-bottom: 0

@mixin loader($size)
	content: " "
	display: block
	width: $size
	height: $size
	position: absolute
	border-radius: 50%
	border: 6px solid #fff
	border-color: #fff transparent
	animation: loading 1.2s linear infinite
	top: calc(50% - ($size / 2))
	left: calc(50% - ($size / 2))
	box-sizing: border-box

	@include basic-transition
