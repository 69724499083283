.tag-element
	display: inline-flex
	align-items: center
	padding: 5px 10px
	border-radius: 15px
	background-color: $background-color
	color: $dark-text-color

	span
		margin-left: 0.3125rem
		font-size: 0.875rem
