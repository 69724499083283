form
	.form-field
		&.phone
			position: relative
			z-index: 9

		> div.input
			position: relative

			&.with-select-prepend
				display: flex
				color: $dark-text-color
				font-size: 1rem
				width: 100%
				height: 2.8rem
				box-sizing: border-box
				border: 1px solid $base-color
				background: white
				box-shadow: inset 0 0 3px $base-color
				outline: none

				@include rounded-corners
				@include basic-transition

				&:hover, &:focus
					border-color: $darker-color
					box-shadow: inset 0 0 5px $darker-color

				&[type="number"]
					appearance: none

					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button
						appearance: none

				> .select-prepend
					display: flex
					flex-flow: row wrap
					align-content: center
					align-items: center
					width: auto
					height: 100%
					border: none
					outline: none
					background: transparent
					padding: 0
					font-size: 1.25rem
					line-height: 1rem
					margin-right: 0.5rem

					> .custom-select
						position: relative
						width: auto
						height: 100%
						display: flex
						background: transparent
						outline: none
						border: none
						padding: 0 0 0 1rem

						.custom-select-control
							display: flex

							> .select-selected
								height: 100%
								display: flex
								align-items: center

							> .icon-wrapper
								display: flex
								flex-wrap: wrap
								align-content: center
								height: 100%
								border-right: 1px solid $base-color
								padding: 0 0.5rem 0 0

								
						.select-items
							position: absolute
							width: 86vw
							max-width: 24rem
							height: 60vw
							max-height: 20rem
							left: 0
							top: 3rem
							display: none
							background: white
							z-index: 999
							overflow-y: scroll
							border: 1px solid $base-color
							border-radius: 4px
							box-shadow: 0 5px 10px rgb(0 0 0 / 15%)
							
							.select-item
								display: flex
								align-items: center
								width: 100%
								height: auto
								background: transparent
								border: none
								padding: 0.8rem 1rem
								text-align: left
								font-size: 1rem
								font-weight: normal
								color: $dark-text-color

								&.selected
									background: $secondary-color

								.flag
									margin-right: 0.5rem

								&:hover
									background: $secondary-color

						&.open
							.select-items
								display: block
							
							.custom-select-control
								> .icon-wrapper
									svg
										transform: rotate(180deg)

					> .prefix
						display: flex
						flex-wrap: wrap
						align-content: center
						height: 100%
						padding-left: 0.5rem
						opacity: 0.5
						font-size: 1rem

				> input
					width: 150px
					padding: 0
					border: none
					background: transparent
					box-shadow: none

@media (min-width: $screen-md-min) // Tablet and up
	form
		.form-field
			> div.input
				&.with-select-prepend
					height: 3.5rem

					> .select-prepend
						> .prefix
							padding: 1rem
							font-size: 1.25rem

					> input
						width: auto
