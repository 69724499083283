form
	.form-field
		&.phone
			position: relative
			z-index: 9

		> div.input
			position: relative

			> input
				color: $dark-text-color
				font-size: 1rem
				width: 100%
				padding: 0.75rem 1rem
				box-sizing: border-box
				border: 1px solid $base-color
				box-shadow: inset 0 0 3px $base-color
				outline: none

				@include rounded-corners
				@include basic-transition

				&:hover, &:focus
					border-color: $darker-color
					box-shadow: inset 0 0 5px $darker-color

				&[type="number"]
					appearance: none

					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button
						appearance: none

			&.with-prepend
				> .prepend
					position: absolute
					top: 50%
					left: 0
					transform: translateY(-50%)
					padding: 0 1rem
					font-size: 1rem
					line-height: 1rem

			&.right
				> input
					text-align: right

			&.large
				> input
					font-size: 2.5rem
					font-weight: 700
					padding: 1.25rem 1.75rem

				&.with-prepend
					> .prepend
						padding: 0 1.75rem
						font-size: 2.5rem
						line-height: 2.5rem
						font-weight: 700

		&.with-error
			> div.input
				> input
					border-color: $error-color
					box-shadow: inset 0 0 3px $error-color

@media (min-width: $screen-md-min) // Tablet and up
	form
		.form-field
			> div.input
				> input
					font-size: 1.25rem
					padding: 1rem 1.25rem

				&.with-prepend
					> .prepend
						padding: 0 1.25rem
						font-size: 1.25rem
						line-height: 1.25rem

				&.large
					> input
						font-size: 3rem
						padding: 1.25rem 2rem

					&.with-prepend
						> .prepend
							padding: 0 2rem
							font-size: 3rem
							line-height: 3rem
