.widget-container
	margin: auto
	width: 100%
	min-width: $screen-xs-min
	max-width: 800px
	border: 1px solid $base-color
	border-radius: 10px
	overflow: auto
	color: $dark-text-color

	.donate-box
		margin: 20px 0

		@include first-last-child-zero

		&:nth-child(2n)
			background: $secondary-color
			padding: 20px
			margin: 0 -20px

			&:last-child
				margin-bottom: -20px

		.donate-header
			margin-bottom: 10px

		.donate-content
			margin-bottom: 20px

			&:last-child
				margin-bottom: 0
	
	.donate-message
		margin-bottom: 40px
		
		h1
			font-size: 72px
			line-height: 1
			margin: 0 0 30px

		h2
			font-size: 60px
			line-height: 1
			margin: 0 0 30px

		h3
			font-size: 48px
			line-height: 1
			margin: 0 0 30px

		h4 
			font-size: 36px
			line-height: 1
			margin: 0 0 30px

		h5
			font-size: 24px
			line-height: 1
			margin: 0 0 30px
			
		h6
			font-size: 18px
			line-height: 1
			margin: 0 0 30px

@media (min-width: $screen-md-min) // Tablet and up
	.widget-container
		border-radius: 25px

		.donate-box
			margin: 50px 0

			&:nth-child(2n)
				background: $secondary-color
				padding: 50px
				margin: 0 -50px

				&:last-child
					margin-bottom: -50px

			.donate-header
				margin-bottom: 20px

			.donate-content
				margin-bottom: 50px
