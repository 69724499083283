.summary-element
	width: 100%
	border-collapse: collapse

	tbody
		tr
			td
				border-bottom: 1px solid $darker-color

	tfoot
		tr
			td
				font-weight: 700

	tbody, tfoot
		tr
			td
				font-size: 1rem
				line-height: 1.25rem
				padding: 0.75rem 0

				&:first-child
					text-align: left

				&:last-child
					text-align: right

@media (min-width: $screen-md-min) // Tablet and up
	.summary-element
		tbody, tfoot
			tr
				td
					font-size: 1.25rem
					line-height: 1.5rem
					padding: 1rem 0
