form
	label.checkbox
		position: relative
		display: grid
		grid-template-columns: 1.5rem auto
		gap: 0.5rem
		align-items: center
		margin-bottom: 0.75rem
		font-size: 1rem
		color: $dark-text-color

		input[type="checkbox"]
			appearance: none
			margin: 0
			height: 1.25rem
			width: 1.25rem
			border: 0.10em solid $lighter-color
			display: grid
			place-content: center
			cursor: pointer
			background-color: transparent

			@include basic-transition
			@include rounded-corners

			&::before
				content: ""
				width: 0.75rem
				height: 0.75rem
				transform: scale(0)
				background-color: $base-color
				transform-origin: bottom center
				clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)

				@include basic-transition

			&:checked
				background-color: $secondary-color

				&::before
					transform: scale(1)

			&:hover
				background-color: $secondary-color

@media (min-width: $screen-md-min) // Tablet and up
	form
		label.checkbox
			margin-bottom: 1rem
			font-size: 1.25rem

			input[type="checkbox"]
				height: 1.5rem
				width: 1.5rem

				&::before
					width: 1rem
					height: 1rem
