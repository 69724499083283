\:root
	--background-color: #fff
	--dark-text-color: #000
	--light-text-color: #fff
	--base-color: #2980b9
	--secondary-color: #ecf0f1
	--lighter-color: #3498db
	--darker-color: #8e44ad
	--error-color: #d63031

// Style
$background-color: var(--background-color)
$dark-text-color: var(--dark-text-color)
$light-text-color: var(--light-text-color)
$base-color: var(--base-color)
$secondary-color: var(--secondary-color)
$lighter-color: var(--lighter-color)
$darker-color: var(--darker-color)
$error-color: var(--error-color)

// Breakpoints
$screen-lg-min: 1240px
$screen-md-min: 768px
$screen-md-max: $screen-lg-min
$screen-sm-min: 480px
$screen-sm-max: $screen-md-min
$screen-xs-min: 280px
$screen-xs-max: $screen-sm-min
