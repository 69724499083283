button[type="button"]
	position: relative
	width: 100%
	height: 60px
	font-size: 1.25rem
	font-weight: 700
	background: $base-color
	color: $light-text-color
	border: 3px solid $base-color
	padding: 10px
	cursor: pointer
	display: flex
	align-items: center
	justify-content: center

	img
		border-radius: 5px
		width: 30px
		height: 30px
		margin: 20px

	&.with-loading
		&::after
			opacity: 0

			@include loader(32px)

	@include rounded-corners
	@include basic-transition

	&.small
		font-size: 1rem

	&:hover, &:focus
		background: $lighter-color
		scale: 1.025

	&.active
		background: $light-text-color
		color: $base-color
		scale: 1.025

	&.loading
		background: $lighter-color
		color: transparent
		scale: unset
		cursor: default

		&::after
			opacity: 1

.buttons
	display: flex
	flex-wrap: wrap
	margin: 10px -10px

	& > button[type="button"]
		margin: 5px
		flex-basis: calc(50% - 10px)


@media (min-width: $screen-md-min) // Tablet and up
	button[type="button"]
		font-size: 1.5rem
		height: 75px

		&.with-loading
			&::after
				@include loader(48px)

		&.small
			font-size: 1.25rem

	.buttons
		margin: 20px -10px

		& > button[type="button"]
			margin: 10px
			flex-basis: calc(33.3333% - 20px)