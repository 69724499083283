form
	.form-field
		margin: 0.5rem 0

		@include first-last-child-zero

		> .error
			display: none
			margin-top: 0.5rem
			font-size: 0.75rem
			color: $error-color
			font-weight: 700

		&.with-error
			> .error
				display: block

@media (max-width: $screen-xs-max) // Small mobile only
	form
		.form-field-xs
			margin: 0.5rem 0

			@include first-last-child-zero

@media (min-width: $screen-md-min) // Tablet and up
	form
		.form-field
			margin: 1rem 0

			> .error
				font-size: 1rem
