.row
	> *
		display: block

@media (max-width: $screen-xs-max) // Small mobile only
	.row
		display: block

		> .space-xs
			padding-top: 10px

@media (min-width: $screen-sm-min) // Mobile and up
	.row
		display: flex
		flex-flow: row wrap

		&.row-wide
			margin: 0 -10px

		> *
			padding: 0 10px

			> .col-wide
				margin: 0 -10px

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) // Mobile only
	.row
		> .space-sm
			padding-top: 10px

		> .col-sm-1-4
			width: 25%

		> .col-sm-2-4
			width: 50%

		> .col-sm-3-4
			width: 75%

		> .col-sm-4-4
			width: 100%

		> .col-sm-offset-1-4
			margin-left: 25%

		> .col-sm-offset-2-4
			margin-left: 50%

		> .col-sm-offset-3-4
			margin-left: 75%

@media (min-width: $screen-md-min) // Tablet and up
	.row
		&.row-wide
			margin: 0 -20px

		> *
			padding: 0 20px

			> .col-wide
				margin: 0 -20px

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) // Tablet only
	.row
		> .space-md
			padding-top: 20px

		> .col-md-1-6
			width: 16.6667%

		> .col-md-2-6
			width: 33.3333%

		> .col-md-3-6
			width: 50%

		> .col-md-4-6
			width: 66.6667%

		> .col-md-5-6
			width: 83.3333%

		> .col-md-6-6
			width: 100%

		> .col-md-offset-1-6
			margin-left: 16.6667%

		> .col-md-offset-2-6
			margin-left: 33.3333%

		> .col-md-offset-3-6
			margin-left: 50%

		> .col-md-offset-4-6
			margin-left: 66.6667%

		> .col-md-offset-5-6
			margin-left: 83.3333%

@media (min-width: $screen-lg-min) // Desktop and up
	.row
		> .col-lg-1-12
			width: 8.3333%

		> .col-lg-2-12
			width: 16.6667%

		> .col-lg-3-12
			width: 25%

		> .col-lg-4-12
			width: 33.3333%

		> .col-lg-5-12
			width: 41.6667%

		> .col-lg-6-12
			width: 50%

		> .col-lg-7-12
			width: 58.3333%

		> .col-lg-8-12
			width: 66.6667%

		> .col-lg-9-12
			width: 75%

		> .col-lg-10-12
			width: 83.3333%

		> .col-lg-11-12
			width: 91.6667%

		> .col-lg-12-12
			width: 100%

		> .col-lg-offset-1-12
			margin-left: 8.3333%

		> .col-lg-offset-2-12
			margin-left: 16.6667%

		> .col-lg-offset-3-12
			margin-left: 25%

		> .col-lg-offset-4-12
			margin-left: 33.3333%

		> .col-lg-offset-5-12
			margin-left: 41.6667%

		> .col-lg-offset-6-12
			margin-left: 50%

		> .col-lg-offset-7-12
			margin-left: 58.3333%

		> .col-lg-offset-8-12
			margin-left: 66.6667%

		> .col-lg-offset-9-12
			margin-left: 75%

		> .col-lg-offset-10-12
			margin-left: 83.3333%

		> .col-lg-offset-11-12
			margin-left: 91.6667%
