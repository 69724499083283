.flag
	display: inline-block
	background-image: url("../../assets/img/flag.png")

	&.flag-unknown
		background: $background-color
		width: 20px
		height: 13px

	&.flag-ar
		width: 20px
		height: 13px
		background-position: -20px -71px

	&.flag-as
		width: 20px
		height: 10px
		background-position: -200px -201px

	&.flag-at
		width: 20px
		height: 13px
		background-position: -40px -71px

	&.flag-au
		width: 20px
		height: 10px
		background-position: -220px -0

	&.flag-aw
		width: 20px
		height: 13px
		background-position: -60px -71px

	&.flag-az
		width: 20px
		height: 10px
		background-position: -220px -10px

	&.flag-ba
		width: 20px
		height: 10px
		background-position: -220px -20px

	&.flag-ax
		width: 20px
		height: 13px
		background-position: -80px -71px

	&.flag-bb
		width: 20px
		height: 13px
		background-position: -0 -85px

	&.flag-bd
		width: 20px
		height: 12px
		background-position: -200px -65px

	&.flag-be
		width: 20px
		height: 17px
		background-position: -0 -25px

	&.flag-bf
		width: 20px
		height: 13px
		background-position: -20px -85px

	&.flag-bg
		width: 20px
		height: 12px
		background-position: -200px -77px

	&.flag-bh
		width: 20px
		height: 12px
		background-position: -200px -89px

	&.flag-bi
		width: 20px
		height: 12px
		background-position: -200px -101px

	&.flag-bj
		width: 20px
		height: 13px
		background-position: -40px -85px

	&.flag-bl
		width: 20px
		height: 13px
		background-position: -60px -85px

	&.flag-bm
		width: 20px
		height: 10px
		background-position: -220px -30px

	&.flag-bn
		width: 20px
		height: 10px
		background-position: -220px -40px

	&.flag-bo
		width: 20px
		height: 14px
		background-position: -0 -57px

	&.flag-bq
		width: 20px
		height: 13px
		background-position: -80px -85px

	&.flag-br
		width: 20px
		height: 14px
		background-position: -20px -57px

	&.flag-bs
		width: 20px
		height: 10px
		background-position: -220px -50px

	&.flag-bt
		width: 20px
		height: 13px
		background-position: -100px -0

	&.flag-bv
		width: 20px
		height: 15px
		background-position: -0 -42px

	&.flag-bw
		width: 20px
		height: 13px
		background-position: -100px -13px

	&.flag-by
		width: 20px
		height: 10px
		background-position: -220px -60px

	&.flag-bz
		width: 20px
		height: 13px
		background-position: -100px -26px

	&.flag-ca
		width: 20px
		height: 10px
		background-position: -220px -70px

	&.flag-cc
		width: 20px
		height: 10px
		background-position: -220px -80px

	&.flag-cd
		width: 20px
		height: 13px
		background-position: -100px -39px

	&.flag-cf
		width: 20px
		height: 13px
		background-position: -100px -52px

	&.flag-ch
		width: 20px
		height: 20px
		background-position: -20px -0

	&.flag-cg
		width: 20px
		height: 13px
		background-position: -100px -65px

	&.flag-ci
		width: 20px
		height: 13px
		background-position: -100px -78px

	&.flag-ck
		width: 20px
		height: 10px
		background-position: -220px -90px

	&.flag-cl
		width: 20px
		height: 13px
		background-position: -0 -98px

	&.flag-cm
		width: 20px
		height: 13px
		background-position: -20px -98px

	&.flag-cn
		width: 20px
		height: 13px
		background-position: -40px -98px

	&.flag-co
		width: 20px
		height: 13px
		background-position: -60px -98px

	&.flag-cr
		width: 20px
		height: 12px
		background-position: -200px -113px

	&.flag-cu
		width: 20px
		height: 10px
		background-position: -220px -100px

	&.flag-cv
		width: 20px
		height: 12px
		background-position: -200px -125px

	&.flag-cw
		width: 20px
		height: 13px
		background-position: -80px -98px

	&.flag-cx
		width: 20px
		height: 10px
		background-position: -220px -110px

	&.flag-cy
		width: 20px
		height: 12px
		background-position: -200px -137px

	&.flag-cz
		width: 20px
		height: 13px
		background-position: -100px -98px

	&.flag-de
		width: 20px
		height: 12px
		background-position: -200px -149px

	&.flag-dg
		width: 20px
		height: 10px
		background-position: -220px -120px

	&.flag-dj
		width: 20px
		height: 13px
		background-position: -120px -0

	&.flag-dk
		width: 20px
		height: 15px
		background-position: -20px -42px

	&.flag-dm
		width: 20px
		height: 10px
		background-position: -220px -130px

	&.flag-do
		width: 20px
		height: 13px
		background-position: -120px -13px

	&.flag-dz
		width: 20px
		height: 13px
		background-position: -120px -26px

	&.flag-ea
		width: 20px
		height: 13px
		background-position: -120px -39px

	&.flag-ec
		width: 20px
		height: 10px
		background-position: -220px -140px

	&.flag-ee
		width: 20px
		height: 13px
		background-position: -120px -52px

	&.flag-eg
		width: 20px
		height: 13px
		background-position: -120px -65px

	&.flag-eh
		width: 20px
		height: 10px
		background-position: -220px -150px

	&.flag-er
		width: 20px
		height: 10px
		background-position: -220px -160px

	&.flag-es
		width: 20px
		height: 13px
		background-position: -120px -78px

	&.flag-et
		width: 20px
		height: 10px
		background-position: -220px -170px

	&.flag-fi
		width: 20px
		height: 12px
		background-position: -200px -161px

	&.flag-fj
		width: 20px
		height: 10px
		background-position: -220px -180px

	&.flag-fk
		width: 20px
		height: 10px
		background-position: -220px -190px

	&.flag-fm
		width: 20px
		height: 11px
		background-position: -20px -201px

	&.flag-fo
		width: 20px
		height: 14px
		background-position: -40px -57px

	&.flag-fr
		width: 20px
		height: 13px
		background-position: -120px -91px

	&.flag-ga
		width: 20px
		height: 15px
		background-position: -40px -42px

	&.flag-gb
		width: 20px
		height: 10px
		background-position: -220px -200px

	&.flag-gd
		width: 20px
		height: 12px
		background-position: -200px -173px

	&.flag-ge
		width: 20px
		height: 13px
		background-position: -0 -111px

	&.flag-gf
		width: 20px
		height: 13px
		background-position: -20px -111px

	&.flag-gg
		width: 20px
		height: 13px
		background-position: -40px -111px

	&.flag-gh
		width: 20px
		height: 13px
		background-position: -60px -111px

	&.flag-gi
		width: 20px
		height: 10px
		background-position: -0 -213px

	&.flag-gl
		width: 20px
		height: 13px
		background-position: -80px -111px

	&.flag-gm
		width: 20px
		height: 13px
		background-position: -100px -111px

	&.flag-gn
		width: 20px
		height: 13px
		background-position: -120px -111px

	&.flag-gp
		width: 20px
		height: 13px
		background-position: -0 -124px

	&.flag-gq
		width: 20px
		height: 13px
		background-position: -20px -124px

	&.flag-gr
		width: 20px
		height: 13px
		background-position: -40px -124px

	&.flag-gs
		width: 20px
		height: 10px
		background-position: -20px -213px

	&.flag-gt
		width: 20px
		height: 13px
		background-position: -60px -124px

	&.flag-gu
		width: 20px
		height: 13px
		background-position: -80px -124px

	&.flag-gw
		width: 20px
		height: 10px
		background-position: -40px -213px

	&.flag-gy
		width: 20px
		height: 12px
		background-position: -60px -45px

	&.flag-hk
		width: 20px
		height: 13px
		background-position: -100px -124px

	&.flag-hm
		width: 20px
		height: 13px
		background-position: -120px -124px

	&.flag-hn
		width: 20px
		height: 10px
		background-position: -60px -213px

	&.flag-hr
		width: 20px
		height: 10px
		background-position: -80px -213px

	&.flag-ht
		width: 20px
		height: 12px
		background-position: -0 -189px

	&.flag-hu
		width: 20px
		height: 10px
		background-position: -100px -213px

	&.flag-ic
		width: 20px
		height: 13px
		background-position: -140px -0

	&.flag-id
		width: 20px
		height: 13px
		background-position: -140px -13px

	&.flag-ie
		width: 20px
		height: 10px
		background-position: -120px -213px

	&.flag-il
		width: 20px
		height: 14px
		background-position: -60px -57px

	&.flag-im
		width: 20px
		height: 10px
		background-position: -140px -213px

	&.flag-in
		width: 20px
		height: 13px
		background-position: -140px -26px

	&.flag-iq
		width: 20px
		height: 13px
		background-position: -140px -39px

	&.flag-io
		width: 20px
		height: 10px
		background-position: -160px -213px

	&.flag-ir
		width: 20px
		height: 11px
		background-position: -40px -201px

	&.flag-is
		width: 20px
		height: 14px
		background-position: -80px -0

	&.flag-it
		width: 20px
		height: 13px
		background-position: -140px -52px

	&.flag-je
		width: 20px
		height: 12px
		background-position: -20px -189px

	&.flag-jm
		width: 20px
		height: 10px
		background-position: -180px -213px

	&.flag-jo
		width: 20px
		height: 10px
		background-position: -200px -213px

	&.flag-jp
		width: 20px
		height: 13px
		background-position: -140px -65px

	&.flag-ke
		width: 20px
		height: 13px
		background-position: -140px -78px

	&.flag-kg
		width: 20px
		height: 12px
		background-position: -40px -189px

	&.flag-kh
		width: 20px
		height: 13px
		background-position: -140px -91px

	&.flag-ki
		width: 20px
		height: 10px
		background-position: -220px -213px

	&.flag-km
		width: 20px
		height: 12px
		background-position: -60px -189px

	&.flag-kn
		width: 20px
		height: 13px
		background-position: -140px -104px

	&.flag-kp
		width: 20px
		height: 10px
		background-position: -0 -223px

	&.flag-kr
		width: 20px
		height: 13px
		background-position: -140px -117px

	&.flag-ks
		width: 20px
		height: 13px
		background-position: -0 -137px

	&.flag-kw
		width: 20px
		height: 10px
		background-position: -20px -223px

	&.flag-ky
		width: 20px
		height: 10px
		background-position: -40px -223px

	&.flag-kz
		width: 20px
		height: 10px
		background-position: -60px -223px

	&.flag-la
		width: 20px
		height: 13px
		background-position: -20px -137px

	&.flag-lb
		width: 20px
		height: 13px
		background-position: -40px -137px

	&.flag-lc
		width: 20px
		height: 10px
		background-position: -80px -223px

	&.flag-li
		width: 20px
		height: 12px
		background-position: -80px -189px

	&.flag-lk
		width: 20px
		height: 10px
		background-position: -100px -223px

	&.flag-lr
		width: 20px
		height: 11px
		background-position: -60px -201px

	&.flag-ls
		width: 20px
		height: 13px
		background-position: -60px -137px

	&.flag-lt
		width: 20px
		height: 12px
		background-position: -100px -189px

	&.flag-lu
		width: 20px
		height: 12px
		background-position: -120px -189px

	&.flag-lv
		width: 20px
		height: 10px
		background-position: -120px -223px

	&.flag-ly
		width: 20px
		height: 10px
		background-position: -140px -223px

	&.flag-ma
		width: 20px
		height: 13px
		background-position: -80px -137px

	&.flag-mc
		width: 20px
		height: 16px
		background-position: -40px -25px

	&.flag-md
		width: 20px
		height: 10px
		background-position: -160px -223px

	&.flag-me
		width: 20px
		height: 10px
		background-position: -180px -223px

	&.flag-mf
		width: 20px
		height: 10px
		background-position: -200px -223px

	&.flag-mg
		width: 20px
		height: 13px
		background-position: -100px -137px

	&.flag-mh
		width: 20px
		height: 11px
		background-position: -80px -201px

	&.flag-mk
		width: 20px
		height: 10px
		background-position: -220px -223px

	&.flag-ml
		width: 20px
		height: 13px
		background-position: -120px -137px

	&.flag-mm
		width: 20px
		height: 11px
		background-position: -100px -201px

	&.flag-mn
		width: 20px
		height: 10px
		background-position: -240px -0

	&.flag-mo
		width: 20px
		height: 13px
		background-position: -140px -137px

	&.flag-mp
		width: 20px
		height: 10px
		background-position: -240px -10px

	&.flag-mq
		width: 20px
		height: 13px
		background-position: -160px -0

	&.flag-mr
		width: 20px
		height: 13px
		background-position: -160px -13px

	&.flag-ms
		width: 20px
		height: 10px
		background-position: -240px -20px

	&.flag-mt
		width: 20px
		height: 13px
		background-position: -160px -26px

	&.flag-mu
		width: 20px
		height: 13px
		background-position: -160px -39px

	&.flag-mv
		width: 20px
		height: 13px
		background-position: -160px -52px

	&.flag-mw
		width: 20px
		height: 13px
		background-position: -160px -65px

	&.flag-mx
		width: 20px
		height: 11px
		background-position: -120px -201px

	&.flag-my
		width: 20px
		height: 10px
		background-position: -240px -30px

	&.flag-mz
		width: 20px
		height: 13px
		background-position: -160px -78px

	&.flag-na
		width: 20px
		height: 13px
		background-position: -160px -91px

	&.flag-nc
		width: 20px
		height: 13px
		background-position: -160px -104px

	&.flag-ne
		width: 20px
		height: 17px
		background-position: -20px -25px

	&.flag-nf
		width: 20px
		height: 10px
		background-position: -240px -40px

	&.flag-ng
		width: 20px
		height: 10px
		background-position: -240px -50px

	&.flag-ni
		width: 20px
		height: 12px
		background-position: -140px -189px

	&.flag-nl
		width: 20px
		height: 13px
		background-position: -160px -117px

	&.flag-no
		width: 20px
		height: 14px
		background-position: -80px -14px

	&.flag-np
		width: 20px
		height: 25px
		background-position: -0 -0

	&.flag-nr
		width: 20px
		height: 10px
		background-position: -240px -60px

	&.flag-nu
		width: 20px
		height: 10px
		background-position: -240px -70px

	&.flag-nz
		width: 20px
		height: 10px
		background-position: -240px -80px

	&.flag-om
		width: 20px
		height: 10px
		background-position: -240px -90px

	&.flag-pa
		width: 20px
		height: 13px
		background-position: -160px -130px

	&.flag-pe
		width: 20px
		height: 13px
		background-position: -0 -150px

	&.flag-pf
		width: 20px
		height: 13px
		background-position: -20px -150px

	&.flag-pg
		width: 20px
		height: 15px
		background-position: -60px -0

	&.flag-ph
		width: 20px
		height: 10px
		background-position: -240px -100px

	&.flag-pk
		width: 20px
		height: 13px
		background-position: -40px -150px

	&.flag-pl
		width: 20px
		height: 13px
		background-position: -60px -150px

	&.flag-pm
		width: 20px
		height: 12px
		background-position: -160px -189px

	&.flag-pn
		width: 20px
		height: 10px
		background-position: -240px -110px

	&.flag-pr
		width: 20px
		height: 13px
		background-position: -80px -150px

	&.flag-ps
		width: 20px
		height: 10px
		background-position: -240px -120px

	&.flag-pt
		width: 20px
		height: 13px
		background-position: -100px -150px

	&.flag-pw
		width: 20px
		height: 13px
		background-position: -120px -150px

	&.flag-py
		width: 20px
		height: 12px
		background-position: -180px -189px

	&.flag-qa
		width: 20px
		height: 8px
		background-position: -240px -233px

	&.flag-re
		width: 20px
		height: 13px
		background-position: -140px -150px

	&.flag-ro
		width: 20px
		height: 13px
		background-position: -160px -150px

	&.flag-rs
		width: 20px
		height: 13px
		background-position: -0 -163px

	&.flag-ru
		width: 20px
		height: 13px
		background-position: -20px -163px

	&.flag-rw
		width: 20px
		height: 13px
		background-position: -40px -163px

	&.flag-sa
		width: 20px
		height: 13px
		background-position: -60px -163px

	&.flag-sb
		width: 20px
		height: 10px
		background-position: -240px -130px

	&.flag-sc
		width: 20px
		height: 10px
		background-position: -240px -140px

	&.flag-sd
		width: 20px
		height: 10px
		background-position: -240px -150px

	&.flag-se
		width: 20px
		height: 13px
		background-position: -80px -163px

	&.flag-sg
		width: 20px
		height: 13px
		background-position: -100px -163px

	&.flag-sh
		width: 20px
		height: 10px
		background-position: -240px -160px

	&.flag-si
		width: 20px
		height: 10px
		background-position: -240px -170px

	&.flag-sj
		width: 20px
		height: 14px
		background-position: -80px -28px

	&.flag-sk
		width: 20px
		height: 13px
		background-position: -120px -163px

	&.flag-sl
		width: 20px
		height: 13px
		background-position: -140px -163px

	&.flag-sm
		width: 20px
		height: 15px
		background-position: -60px -15px

	&.flag-sn
		width: 20px
		height: 13px
		background-position: -160px -163px

	&.flag-so
		width: 20px
		height: 13px
		background-position: -180px -0

	&.flag-sr
		width: 20px
		height: 13px
		background-position: -180px -13px

	&.flag-ss
		width: 20px
		height: 10px
		background-position: -240px -180px

	&.flag-st
		width: 20px
		height: 10px
		background-position: -240px -190px

	&.flag-sv
		width: 20px
		height: 11px
		background-position: -140px -201px

	&.flag-sx
		width: 20px
		height: 13px
		background-position: -180px -26px

	&.flag-sy
		width: 20px
		height: 13px
		background-position: -180px -39px

	&.flag-sz
		width: 20px
		height: 13px
		background-position: -180px -52px

	&.flag-ta
		width: 20px
		height: 10px
		background-position: -240px -200px

	&.flag-tc
		width: 20px
		height: 10px
		background-position: -240px -210px

	&.flag-td
		width: 20px
		height: 13px
		background-position: -180px -65px

	&.flag-tf
		width: 20px
		height: 13px
		background-position: -180px -78px

	&.flag-tg
		width: 20px
		height: 13px
		background-position: -180px -91px

	&.flag-th
		width: 20px
		height: 13px
		background-position: -180px -104px

	&.flag-tj
		width: 20px
		height: 10px
		background-position: -240px -220px

	&.flag-tk
		width: 20px
		height: 10px
		background-position: -0 -233px

	&.flag-tl
		width: 20px
		height: 10px
		background-position: -20px -233px

	&.flag-tm
		width: 20px
		height: 13px
		background-position: -180px -117px

	&.flag-tn
		width: 20px
		height: 13px
		background-position: -180px -130px

	&.flag-to
		width: 20px
		height: 10px
		background-position: -40px -233px

	&.flag-tr
		width: 20px
		height: 13px
		background-position: -180px -143px

	&.flag-tt
		width: 20px
		height: 12px
		background-position: -200px -189px

	&.flag-tv
		width: 20px
		height: 10px
		background-position: -60px -233px

	&.flag-tw
		width: 20px
		height: 13px
		background-position: -180px -156px

	&.flag-tz
		width: 20px
		height: 13px
		background-position: -0 -176px

	&.flag-ua
		width: 20px
		height: 13px
		background-position: -20px -176px

	&.flag-ug
		width: 20px
		height: 13px
		background-position: -40px -176px

	&.flag-um
		width: 20px
		height: 11px
		background-position: -160px -201px

	&.flag-us
		width: 20px
		height: 11px
		background-position: -180px -201px

	&.flag-uy
		width: 20px
		height: 13px
		background-position: -60px -176px

	&.flag-uz
		width: 20px
		height: 10px
		background-position: -80px -233px

	&.flag-va
		width: 20px
		height: 20px
		background-position: -40px -0

	&.flag-vc
		width: 20px
		height: 13px
		background-position: -80px -176px

	&.flag-ve
		width: 20px
		height: 13px
		background-position: -100px -176px

	&.flag-vg
		width: 20px
		height: 10px
		background-position: -100px -233px

	&.flag-vi
		width: 20px
		height: 13px
		background-position: -120px -176px

	&.flag-vn
		width: 20px
		height: 13px
		background-position: -140px -176px

	&.flag-vu
		width: 20px
		height: 12px
		background-position: -0 -201px

	&.flag-wf
		width: 20px
		height: 15px
		background-position: -60px -30px

	&.flag-ws
		width: 20px
		height: 10px
		background-position: -120px -233px

	&.flag-xk
		width: 20px
		height: 14px
		background-position: -80px -42px

	&.flag-ye
		width: 20px
		height: 13px
		background-position: -160px -176px

	&.flag-yt
		width: 20px
		height: 13px
		background-position: -180px -176px

	&.flag-za
		width: 20px
		height: 13px
		background-position: -200px -0

	&.flag-zm
		width: 20px
		height: 13px
		background-position: -200px -13px

	&.flag-zw
		width: 20px
		height: 10px
		background-position: -140px -233px

	&.flag-ac
		width: 20px
		height: 10px
		background-position: -160px -233px

	&.flag-ad
		width: 20px
		height: 14px
		background-position: -80px -56px

	&.flag-ae
		width: 20px
		height: 10px
		background-position: -180px -233px

	&.flag-af
		width: 20px
		height: 13px
		background-position: -200px -26px

	&.flag-ag
		width: 20px
		height: 13px
		background-position: -200px -39px

	&.flag-ai
		width: 20px
		height: 10px
		background-position: -200px -233px

	&.flag-al
		width: 20px
		height: 14px
		background-position: -0 -71px

	&.flag-am
		width: 20px
		height: 10px
		background-position: -220px -233px

	&.flag-ao
		width: 20px
		height: 13px
		background-position: -200px -52px
