.progress-bar-wrapper
	padding: 50px 50px 0
	background-color: $background-color

	.current
		float: left

	.goal
		float: right

	.progress-bar
		width: 100%
		background-color: #f3f3f3
		border-radius: 7px
		border: 1px solid $base-color
		overflow: hidden

		.progress-bar-fill
			height: 30px
			width: 0
			background-color: #4CAF50
			border-radius: 7px 0 0 7px
			transition: width 0.5s
