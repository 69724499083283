@import 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'

html, body
	font-family: Roboto, sans-serif
	font-size: 16px
	line-height: 20px

h1, h2, h3, h4, p
	margin-top: 1rem
	margin-bottom: 1rem

h1
	font-size: 2.25rem
	line-height: 2.75rem

h2
	font-weight: 500
	font-size: 1.5rem
	line-height: 2.25rem

h3
	font-weight: 500
	font-size: 1.25rem
	line-height: 1.75rem

h4
	font-weight: 500
	font-size: 1.125rem
	line-height: 1.55rem

p
	font-size: 1rem

	&.big
		font-size: 1.125rem
		line-height: 1.75rem

h1:first-child, h2:first-child, h3:first-child, h4:first-child, p:first-child
	margin-top: 0

h1:last-child, h2:last-child, h3:last-child, h4:last-child, p:last-child
	margin-bottom: 0

.text-left
	text-align: left

.text-center
	text-align: center

.text-right
	text-align: right
