form
	.form-field
		> div.select
			position: relative

			> select
				color: $dark-text-color
				font-size: 1rem
				width: 100%
				padding: 0.75rem 1rem
				box-sizing: border-box
				border: 1px solid $base-color
				box-shadow: inset 0 0 3px $base-color
				outline: none
				appearance: none
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10L12 15L17 10H7Z' fill='black' /%3E%3C/svg%3E")
				background-repeat: no-repeat
				background-position: 99% center 

				@include rounded-corners
				@include basic-transition

				&:hover, &:focus
					border-color: $darker-color
					box-shadow: inset 0 0 5px $darker-color

		&.with-error
			> div.select
				> select
					border-color: $error-color
					box-shadow: inset 0 0 3px $error-color

@media (min-width: $screen-md-min) // Tablet and up
	form
		.form-field
			> div.select
				> select
					font-size: 1.25rem
					padding: 1rem 1.25rem
