.notification-element
	padding: 20px
	margin: 20px 0

	@include first-last-child-zero
	@include rounded-corners

	&.error
		background: $error-color
		color: $light-text-color
